import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import bozbarkIcon from '../assets/bozbark-icon.ico'
import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
        }}
      >
        <img
          src={bozbarkIcon}
          alt={`Bozbark`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
          }}
        />
        <p>
          An Ozarker writing about self-reliance, doing things that are meaningful, and staying connected to reality in a digital world.
          <br />
          <a href="https://teespring.com/stores/bozbark-store">Check Out Our Merch</a>
        </p>
      </div>
    )
  }
}

export default Bio
